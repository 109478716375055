body.dark {
	/* Primary */
	--primary-20: #7EA9FF;
	--primary-40: #4D88FF;
	--primary-60: #2264E5;
	--primary-70: #1A66FF; /* Brand */
	--primary-90: #062F80;
	--primary-100: #081F4D;
	--primary-20-light: #0E388C;

	/* Filters */
	--filter-to-primary: brightness(0) saturate(100%) invert(27%) sepia(82%) saturate(7495%) hue-rotate(222deg) brightness(103%) contrast(91%);

	/* Alerts */
	--background-primary: #151526;

	/* Contrast */
	--light: #FFFFFF;
	--dark: #0E121B;
	--brand-contrast: #FAFAFA;
	--brand-gradient: linear-gradient(221deg, var(--primary-40) 100%, var(--primary-20) 50%, var(--primary-70) 0%);

	/* Shadow */
	--backdrop: rgba(0, 0, 0, 50%);
	--shadow-0: #595B7022;

	/* Gray */
	--gray-0: #FCFDFF;
	--gray-1: #F3F7FF;
	--gray-2: #DDE5F5;
	--gray-3: #C3CFE5;
	--gray-4: #8699BF;
	--gray-5: #5C7099;
	--gray-6: #405580;
	--gray-7: #334466;
	--gray-8: #2D3B59;
	--gray-9: #243047;
	--gray-10: #1F293D;
	--gray-11: #1A2233;
	--gray-12: #141B29;
	--gray-13: #111621;
	--gray-14: var(--dark);

	/* Error */
	--error-normal: #FF6666;
	--error-hover: #FE7F7F;
	--error-tint: #FFCCCC;
	--error-off: #BD1318;
	--error-background-alt: #330A0A;
	--error-background: var(--gray-13);
	--error-hover-dark: var(--error-hover);
	--error-tint-dark: var(--error-tint);
	--error-tint-light: #991F1F;

	/* Warning */
	--warning-normal: #FFBC2B;
	--warning-hover: #FFC74D;
	--warning-tint: #FFDF99;
	--warning-off: #AF8019;
	--warning-background-alt: #332507;
	--warning-background: var(--gray-13);
	--warning-off-light: #FFDF99;
	--warning-tint-light: #996F15;

	/* Success */
	--success-normal: #4FF157;
	--success-hover: #92FF97;
	--success-tint: #CCFFCF;
	--success-off: #3AAE40;
	--success-background-alt: #0B260D;
	--success-background: var(--gray-13);
	--success-off-light: #99FF9E;
	--success-tint-light: #26802B;

	/* Information */
	--information-normal: #22A9FF;
	--information-hover: #66C3FF;
	--information-tint: #CCEBFF;
	--information-off: #007BC9;
	--information-background-alt: #001E33;
	--information-background: var(--gray-13);
	--information-off-light: #99D7FF;

	/* Processing */
	--processing-normal: #A933FF;
	--processing-hover: #BF66FF;
	--processing-tint: #EACCFF;
	--processing-off: #7F14CC;
	--processing-background-alt: #1E0033;
	--processing-background: var(--gray-13);

	/* Colors */
	--special-green-1: #00B4A0;
	--special-green-2: #50E3C2;
	--special-green-3: #61BD4F;
	--special-green-4: #77DC01;
	--special-orange-1: #F3AB4B;
	--special-orange-2: #FFAE1C;
	--special-yellow-1: #F0CC18;
	--special-yellow-2: #FFE224;
	--special-violet-1: #9900EF;
	--special-violet-2: #9682E7;
	--special-purple-1: #9F5BDA;
	--special-purple-2: #C377E0;
	--special-red-1: #CC1B00;
	--special-red-2: #FF2200;
	--special-blue-1: #1C30CC;
	--special-blue-2: #EBF4FF;
	--special-pink-1: #BB47A9;
}
