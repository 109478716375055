@import url("~@ionic/angular/css/core.css");
@import url("~@ionic/angular/css/normalize.css");
@import url("~@ionic/angular/css/structure.css");
@import url("~@ionic/angular/css/typography.css");
@import url("~@ionic/angular/css/display.css");
@import url("~@ionic/angular/css/padding.css");
@import url("~@ionic/angular/css/float-elements.css");
@import url("~@ionic/angular/css/text-alignment.css");
@import url("~@ionic/angular/css/text-transformation.css");
@import url("~@ionic/angular/css/flex-utils.css");
@import url("~viewerjs/dist/viewer.css");
@import url("~mapbox-gl/dist/mapbox-gl.css");
@import url("~mapbox-gl-style-switcher/styles.css");
@import url("~@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css");
@import url("~@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css");
@import url("~@angular/cdk/overlay-prebuilt.css");
@import url("app/theme/variables.scss");
@import url("fonts.scss");
@import url("scrollbar.scss");
@import url("ionic.scss");
@import url("app/theme/typography.scss");
@import url("air-datepicker/air-datepicker.css");

.button {
	text-transform: none;
	font-size: 15px;
}

* {
	user-select: none;
}

// Input autocomplete background (prevent yellow color on chrome)
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: color 9999s ease-out, background-color 9999s ease-out;
	transition-delay: 9999s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	appearance: none;
	margin: 0;
}

input[type="color"] {
	border-radius: 0;
	padding: 0;
	border: none;
}

input[type="color"]::-moz-color-swatch {
	border: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
	border-radius: 0;
}

input[type="color"]::-webkit-color-swatch {
	border: none;
}

/* Modal Container */
.modal-container {
	z-index: 10;

	&::part(content) {
		width: 80vw;
		max-width: 700px;
		max-height: 80vh;
		top: auto !important;
		left: auto !important;
		position: unset !important;
	}
}

/* Modal Container Fullscreen */
.modal-container-fullscreen {
	z-index: 10;

	&::part(content) {
		width: 100vw;
		height: 100vh;
		max-width: 100% !important;
		top: auto !important;
		left: auto !important;
		position: unset !important;
	}
}

.cdk-virtual-scroll-content-wrapper {
	width: 100%;
}

zxing-scanner video {
	height: 100% !important;
}

ion-label,
ion-radio,
ion-checkbox,
ion-textarea,
ion-select,
ionic-selectable {
	font-size: 14px !important;
}
