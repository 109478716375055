body.light {
	/* Primary */
	--primary-20: #0E388C;
	--primary-40: #0941B2;
	--primary-60: #4D88FF;
	--primary-70: #0C59F2; /* Brand */
	--primary-90: #B2CCFF;
	--primary-100: #E5EEFF;
	--primary-70-dark: #1A66FF;

	/* Filters */
	--filter-to-primary: brightness(0) saturate(100%) invert(21%) sepia(98%) saturate(5487%) hue-rotate(222deg) brightness(103%) contrast(91%);

	/* Alerts */
	--background-primary: #F7FBFF;

	/* Contrast */
	--light: #FFFFFF;
	--dark: #080810;
	--brand-contrast: #FAFAFA;
	--brand-gradient: linear-gradient(221deg, var(--primary-40) 100%, var(--primary-20) 50%, var(--primary-70) 0%);

	/* Shadow */
	--backdrop: rgba(0, 0, 0, 50%);
	--shadow-0: #0E1B2322;

	/* Gray */
	--gray-0: #14171F;
	--gray-1: #1B202B;
	--gray-2: #2E374A;
	--gray-3: #4F5F7F;
	--gray-4: #778DB7;
	--gray-5: #8BA1CC;
	--gray-6: #9DB1DA;
	--gray-7: #ACBFE5;
	--gray-8: #C2D2F2;
	--gray-9: #CDDCFB;
	--gray-10: #DBE8FF;
	--gray-11: #E5EEFF;
	--gray-12: #EBF1FF;
	--gray-13: #F5F8FF;
	--gray-14: #FCFDFF;

	/* Error */
	--error-normal: #E34C4C;
	--error-hover: #BD3539;
	--error-tint: #991F1F;
	--error-off: #FF9999;
	--error-background: #FEF6F6;
	--error-background-alt: #F2CBCA;
	--error-hover-dark: #FE7F7F;
	--error-tint-dark: #FFCCCC;
	--error-tint-light: var(--error-tint);

	/* Warning */
	--warning-normal: #F6B322;
	--warning-hover: #CC9214;
	--warning-tint: #996F15;
	--warning-off: #FFDF99;
	--warning-background: #FFFBF4;
	--warning-background-alt: #FDEBC4;
	--warning-off-light: var(--warning-off);
	--warning-tint-light: var(--warning-tint);

	/* Success */
	--success-normal: #56BD5B;
	--success-hover: #3E9645;
	--success-tint: #26802B;
	--success-off: #99FF9E;
	--success-background: #F6FCF7;
	--success-background-alt: #D0E9D0;
	--success-off-light: var(--success-off);
	--success-tint-light: var(--success-tint);

	/* Information */
	--information-normal: #0099FA;
	--information-hover: #007BD4;
	--information-tint: #005999;
	--information-off: #99D7FF;
	--information-background: #F2FAFF;
	--information-background-alt: #B6D7F0;
	--information-off-light: var(--information-off);

	/* Processing */
	--processing-normal: #902BD9;
	--processing-hover: #6800B2;
	--processing-tint: #52008C;
	--processing-off: #CA80FF;
	--processing-background: #FCF7FF;
	--processing-background-alt: #DAB6F6;

	/* Colors */
	--special-green-1: #00B4A0;
	--special-green-2: #50E3C2;
	--special-green-3: #61BD4F;
	--special-green-4: #77DC01;
	--special-orange-1: #F3AB4B;
	--special-orange-2: #FFAE1C;
	--special-yellow-1: #F0CC18;
	--special-yellow-2: #FFE224;
	--special-violet-1: #9900EF;
	--special-violet-2: #9682E7;
	--special-purple-1: #9F5BDA;
	--special-purple-2: #C377E0;
	--special-red-1: #CC1B00;
	--special-red-2: #FF2200;
	--special-blue-1: #1C30CC;
	--special-blue-2: #EBF4FF;
	--special-pink-1: #BB47A9;
}
